import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

interface Props {
  data: {
    philanthropyDefinition: Core.ImageAttributes;
    renataZilliMontero: Core.ImageAttributes;
    andreasBaum: Core.ImageAttributes;
    isabelStuder: Core.ImageAttributes;
    alethiaTilford: Core.ImageAttributes;
    ormeWilsonJrCarter: Core.ImageAttributes;
    ormeWilsonJrTito: Core.ImageAttributes;
    ormeWilsonSr: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const {
    philanthropyDefinition,
    renataZilliMontero,
    andreasBaum,
    isabelStuder,
    alethiaTilford,
    ormeWilsonJrCarter,
    ormeWilsonJrTito,
    ormeWilsonSr
  } = props.data;

  return (
    <Layout departmentSlug="philanthropy" title="Philanthropy">
      <Core.ContentWrapper>
        <Core.DepartmentLabel>Philanthropy</Core.DepartmentLabel>
        <Core.Flag>Shape the World</Core.Flag>

        <Core.ContentSection
          id="opening-the-door-to-international-education"
          showShareLink
          marginalia={
            <>
              <Core.FullWidthImage
                image={renataZilliMontero}
                alt="Renata Zilli Montero"
                caption="Renata Zilli Montero"
              />

              <Core.FullWidthImage
                image={andreasBaum}
                alt="Andreas Baum"
                caption="Andreas Baum"
              />

              <Core.FullWidthImage
                image={isabelStuder}
                alt="Isabel Studer"
                caption="Isabel Studer"
              />
            </>
          }
        >
          <Core.PrimaryHeading>
            Opening the Door to International Education
          </Core.PrimaryHeading>

          <Core.LargeText>
            Data helps <Core.BoldText>Renata Zilli Montero</Core.BoldText>, MA
            ’21, dream big.
          </Core.LargeText>

          <Core.LargeText>
            “Thanks to advances in technology, we are starting to measure and
            quantify, in large proportions, human behavior at every level, from
            small localities to global environments,” says Zilli Montero, who
            researches international political economies, specifically North
            American international commercial relations, for her SAIS degree in
            International Economics and Latin American Studies.
          </Core.LargeText>

          <p>
            “We have more elements to understand more about our quality as human
            beings. And the more we learn from ourselves, the more opportunity
            we have to provide solutions to improve our quality of life,” adds
            Zilli Montero, who holds a BA and MA in International Relations from
            the Tecnológico de Monterrey in Mexico, her home country, and an MA
            in International Trade from Sydney’s Macquarie University.
          </p>
          <p>
            It is SAIS’ strength in quantitative reasoning that inspired her to
            enroll, and it’s the driver for her dreams post-graduation this May.
            “Economies are becoming more data-centric, and I knew that I needed
            to integrate more quantitative analytic skills to excel as a social
            scientist,” she explains of her plans to enroll in a PhD program in
            the United States and continue her career in academia and research.
          </p>
          <p>
            In 2019, before she began the SAIS program, Zilli Montero founded
            the Global Dreamers Project, a pro-bono mentorship program focused
            on advising students and young professionals from the southern (and
            typically less affluent) Mexican states with their applications to
            international scholarships or fellowships. To date, she’s shepherded
            14 people to receiving scholarships to study abroad and hopes to
            ramp up her mentoring program after graduation.
          </p>
          <p>
            “Having the opportunity to receive an international education
            contributes to building better societies and creates better and
            resilient professionals, who become more conscious of the global
            society in which we live,” she says.
          </p>
          <p>
            This is precisely the “pay-it-forward” inspiration that Ambassador{' '}
            <Core.BoldText>Andreas Baum</Core.BoldText>, MA ’90, currently the
            Swiss ambassador to Japan, hoped for when he established the Andreas
            Baum Fellowship to support exceptional international MA students
            like Zilli Montero.
          </p>
          <p>
            “Back in 1988, SAIS admitted me, a foreigner with an improbable
            medical education,” shared Baum, who arrived at SAIS with a medical
            degree from the University of Lausanne, in a recent email exchange.
            “It was an exciting period, marked by the fall of the Berlin Wall, a
            time of hopes and possibilities. Because SAIS gave me a chance,
            because of my parents’ generosity and patience, I discovered my
            professional calling. Today, I want to give others such a chance.”
          </p>
          <p>
            During Baum’s 30-year diplomatic career, he has served Switzerland
            as Ambassador to Nigeria, Israel, India, and since 2020, Japan. He
            appreciates that similar threads are woven through Zilli Montero’s
            SAIS experience and his own. “I learned to analyze rationally,
            rigorously, critically, with openness and curiosity, thus becoming a
            generalist with a taste for learning always,” he says. “Renata and
            the other [Baum] recipients want to work hard at making their
            countries and the world a better place. This makes me truly
            hopeful.”
          </p>
          <p>
            During her time at SAIS, Zilli Montero pursued internships that
            further honed her analytic skills. With the NAFTA office in the
            Mexican Embassy of Mexico, she researched international trade data.
            At Mexico’s Sostenibilidad Global — a nonprofit creating sustainable
            solutions to address the climate crisis — Zilli Montero focused on
            urban public policy. Founded by{' '}
            <Core.BoldText>Isabel Studer</Core.BoldText>, who received her MA
            and PhD from SAIS, Sostenibilidad Global uses a data-driven
            “bottom-up” model to drive systemic transformation.
          </p>
          <p>
            At the Geneva Trade Week 2020, she served on a trade and gender
            panel with her fellow female TradeTankMX (TTMX) colleagues. Zilli
            Montero is a founding partner and chair of the Strategic Planning
            Committee TTMX think tank comprising young, passionate global
            leaders.
          </p>
          <p>
            “This was my first opportunity to share my ideas on the importance
            of having inclusive trade with a gender perspective,” she says. “I
            could not have excelled at this conference if it were not for the
            knowledge and experiences I have acquired at SAIS.”{' '}
            <em>Sarah Achenbach</em>
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          id="annual-gifts-make-a-lasting-impact-on-students"
          showShareLink
          marginalia={
            <Core.FullWidthImage
              image={alethiaTilford}
              alt="Alethia Tilford"
              caption="Alethia Tilford"
            />
          }
        >
          <Core.PrimaryHeading>
            Annual Gifts Make a Lasting Impact on Students
          </Core.PrimaryHeading>
          <Core.LargeText>
            Second-year student <Core.BoldText>Alethia Tilford</Core.BoldText>{' '}
            plans to become a Foreign Service officer specializing in public
            diplomacy for the U.S. Department of State. During her two years at
            SAIS, Tilford, a MA candidate in Latin American Studies, has
            received several fellowships made possible by alumni through annual
            gifts, all of which have played a vital role in bringing her closer
            to this goal.
          </Core.LargeText>
          <p>
            “As a first-generation college graduate, finances were tight in
            undergrad and even tighter now that I’m in graduate school,” says
            Tilford, who most recently received the Christian Herter Society
            (CHS) Fellowship, which is providing her with $5,000 in financial
            aid during her last semester. “I am so grateful for the generosity
            of alumni and their willingness to give back.”
          </p>
          <p>
            The CHS Fellowship — named after Christian Herter, the co-founder of
            SAIS and a former U.S. Secretary of State, and funded through gifts
            including those of $2,500 and above — represents one of several
            annual giving streams that collectively generated $2.4 million last
            year for essential student financial aid, internships, research
            trips, and more.
          </p>
          <p>
            Gifts at each level are making a tangible difference for students. A
            $250 gift can cover living expenses for a student who is facing
            unexpected financial hardship, while a $1,500 gift allows a student
            to take part in a career-changing internship. Smaller gifts have a
            big impact, too: Last fiscal year, contributions between $1 and $100
            made through the annual fund added up to more than $40,000 that was
            put toward financial aid for students.
          </p>

          <p>
            During her time at SAIS, Tilford — a U.S. Presidential Scholar
            during her last year of high school in Milwaukee, Wisconsin, and the
            recipient of the Boys & Girls Clubs of Greater Milwaukee 2015 Youth
            of the Year Award — has received a fellowship every semester.
          </p>
          <p>
            With this support, shortly after graduating from Howard University
            in Washington, D.C., with degrees in management communications and
            Spanish, Tilford studied at the SAIS campus in Bologna, Italy,
            during her first year. There, she connected with the African migrant
            population — a defining opportunity since she also hopes to work
            with migrants and asylum seekers in the future. A fellowship also
            led her to intern with the National Association of Displaced
            Afro-Colombians (AFRODES), where she got hands-on experience working
            with Black people impacted by internal displacement in Colombia.
          </p>
          <p>
            “These fellowships have given me the opportunity to focus on my
            education,” says Tilford, adding that alumni also give their time at
            events like career panels and talks, in addition to monetary
            contributions. “Alumni support is what makes SAIS such a special
            institution,” she says. “They have inspired me to ensure that I give
            back to this institution in the best way I can after graduation.”
          </p>
          <p>
            Tilford’s most recent fellowship, the CHS Fellowship in honor of the
            Class of 1994, was made possible by alumnus{' '}
            <Core.BoldText>Bruce Comer</Core.BoldText> ’94, who was once a
            recipient of financial aid himself during his time at SAIS. “It
            makes a big difference,” says Comer, founder and managing director
            of Ocean Park Advisors, an investment bank in Los Angeles,
            California. “It’s great to see the energy and vision that SAIS
            students bring to the world, [and] I’m glad that I’m able to give
            back.” <em>Jennifer Walker</em>
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          id="sais-europe-the-power-of-philanthropy"
          showShareLink
        >
          <Core.PrimaryHeading>
            SAIS Europe: The Power of Philanthropy
          </Core.PrimaryHeading>
          <Core.LargeText>
            Thanks to a transformational $10 million gift,{' '}
            <Core.BoldText>James Anderson</Core.BoldText>, B ’81 — Johns Hopkins
            University trustee and SAIS Europe council chairman — turned what
            could have been a terrible year for the Bologna Center into the top
            fundraising year in its history.
          </Core.LargeText>

          <Core.FullWidthImage
            image={philanthropyDefinition}
            alt="Philanthropy"
          />

          <p>
            Coming at the peak of the pandemic, Anderson’s gift not only offered
            relief funding for the COVID emergency and funds to support faculty
            and students, it also helped mark SAIS’ 65th anniversary with a more
            hopeful look to its future. What’s more, to double the impact of his
            gift, Anderson offered an unprecedented $4 million matching funds
            program. As of May 16, 2021, 40% (about $1.6 million) of the
            matching funds have been used to match an equivalent amount in new
            gifts from more than 400 donors (of which 17% are first-time
            donors).
          </p>
          <p>
            We would like to thank all of our supporters who have responded so
            generously to this incredible act of generosity. A full list of
            donors is available at this{' '}
            <a href="https://europe.jhu.edu/initiatives/special-jamatchingfund.cfm">
              Honor Roll
            </a>
            . Among others, we want to acknowledge several{' '}
            <em>Bolognese couples</em> who have responded to the initiative:
          </p>

          <ul>
            <li>
              <Core.BoldText>
                <em>Barbara and Raffaele Santoro</em>
              </Core.BoldText>{' '}
              (Bologna, 1960) for the establishment and continuing support of
              the Santoro Fellowship, which has helped fund 26 students in
              Bologna.
            </li>
            <li>
              <Core.BoldText>
                <em>Naneen and Axel Neubohn</em>
              </Core.BoldText>{' '}
              (Bologna, 1963) for their support of the Grove Haines Chair,
              noting that
              <em>“without Grove Haines there would be no Bologna Center.”</em>
            </li>
            <li>
              <Core.BoldText>
                <em>Erika and Hasan Teoman</em>
              </Core.BoldText>{' '}
              (Bologna, 1980–82) and{' '}
              <Core.BoldText>
                <em>Daniela and Ajay Kaisth</em>
              </Core.BoldText>{' '}
              (Bologna, 1989) for the establishment of the Amaduzzi-Aragno
              Internship Fund, who observed{' '}
              <em>“Success is important but kindness matters!”</em>
            </li>
            <li>
              <Core.BoldText>
                <em>Zach and Julia Messitte</em>
              </Core.BoldText>{' '}
              (Bologna, 1994) for the establishment of the Class of 1994
              Fellowship Fund, stating that{' '}
              <em>
                “a year in Bologna should be an opportunity open to all
                students.”
              </em>
            </li>
            <li>
              <Core.BoldText>
                <em>Michiel Vriens and Midori Tanaka</em>
              </Core.BoldText>{' '}
              (Bologna, 2019) for their support of the Amici di Bologna Student
              Fund, who commented that{' '}
              <em>
                “our year at SAIS Europe was truly transformative, from living
                in Bologna to forging long-lasting relationships with our peers
                and professors. We wanted to give back to offer future students
                the opportunity to experience the same.”
              </em>
            </li>
          </ul>

          <p>
            The matching program runs until matching funds are entirely utilized
            and no later than June 2023. Don’t miss out on the opportunity to
            double up the impact of your gift to SAIS Europe. Up to $2.2 million
            in matching funds are still available for the following areas:
          </p>
          <p>
            <Core.BoldText>Professorships Sustainability Fund:</Core.BoldText>{' '}
            Faculty members are key to any academic institution, and this is
            particularly true for the intimate SAIS Europe community. Support of
            the Kenneth H. Keller Chair and the C. Grove Haines Chair offers
            much deserved recognition and celebration to resident faculty
            members for their dedication and accomplishments.{' '}
            <a href="https://europe.jhu.edu/initiatives/special-jamatchingfund.cfm">
              Read more
            </a>
          </p>
          <p>
            <Core.BoldText>COVID Emergency Relief Fund:</Core.BoldText> With the
            primary goals of ensuring safety while offering the best possible
            educational experience, the COVID-19 Emergency Fund alleviates the
            financial burden on SAIS Europe resources and allows the school to
            offer immediate relief to its community members and to offset the
            increased pandemic management costs, including necessary technical
            infrastructure and required measures to resume normal operations.{' '}
            <a href="https://europe.jhu.edu/initiatives/special-jamatchingfund.cfm">
              Read more
            </a>
          </p>
          <p>
            <Core.BoldText>Programmatic Integration Fund:</Core.BoldText>{' '}
            Academic integration paves the way for growth of new ideas and new
            courses to help SAIS students anticipate and face new challenges
            worldwide. SAIS Europe is the ideal location for faculty exchange
            across SAIS and Johns Hopkins University, and development of new
            virtual learning opportunities and new master’s programs.{' '}
            <a href="https://europe.jhu.edu/initiatives/special-jamatchingfund.cfm">
              Read more
            </a>
          </p>
          <p>
            We are also excited to announce that matching funds for the{' '}
            <Core.BoldText>Student Opportunity Fund</Core.BoldText> (including
            new and existing student fellowships, internships, and experiential
            learning opportunities) are almost entirely utilized, up to a total
            of about $1,310,000 in new gifts,{' '}
            <Core.BoldText>
              leaving only $140,000 available for additional new gifts
            </Core.BoldText>{' '}
            (as of May 16, 2021).
          </p>
          <p>
            Some of the new gifts that benefited from the match have supported
            the creation of new endowed fellowships at SAIS Europe, which will
            have a perpetual impact on the life of its students. Kudos to the{' '}
            <Core.BoldText>
              Bologna Classes of 1980, 1982, and 1994
            </Core.BoldText>{' '}
            for their tireless dedication to this endeavor.
          </p>
          <p>
            If you are interested in{' '}
            <Core.BoldText>
              establishing your named matching program
            </Core.BoldText>{' '}
            to stimulate additional gifts to the SAIS Europe Student Opportunity
            Fund, please contact the{' '}
            <a href="mailto:sais.eu.development@jhu.edu">
              SAIS Europe Office of Development
            </a>
            .
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          id="Addressing Inequity through Education"
          showShareLink
          marginalia={
            <>
              <Core.FullWidthImage
                image={ormeWilsonJrTito}
                alt="Orme Wilson Jr. Tito"
                caption="Picture of Orme Wilson Jr. (on the right shaking hands), American Consul General in Zagreb, Yugoslavia, welcoming Josip Broz Tito, President of Yugoslavia (shaking hands on the left), to the United States Pavilion at the Zagreb Fair in September 1970. Directly to the right of Orme Wilson Jr. is his wife Mildred Wilson, and directly to the left of President Tito is his wife Madame Broz."
              />
              <Core.FullWidthImage
                image={ormeWilsonJrCarter}
                alt="Orme Wilson Jr. Carter"
                caption="Picture of Orme Wilson Jr. meeting President Jimmy Carter in 1977 at the U.S. Mission to the United Nations.  Directly to Wilson’s left and Carter’s right is Richard Holbrooke, then Assistant Secretary of State for East Asian and Pacific Affairs.  At that time Wilson was posted to the U.S. Mission to the United Nations in New York City."
              />
              <Core.FullWidthImage
                image={ormeWilsonSr}
                alt="Orme Wilson Sr."
                caption="Orme Wilson Sr."
              />
            </>
          }
        >
          <Core.SecondaryHeading>
            Addressing Inequity through Education
          </Core.SecondaryHeading>

          <p>
            <Core.BoldText>Orme Wilson III</Core.BoldText>, MA ’79, understands
            that inspiration can span generations.
          </p>

          <p>
            In 2020, Wilson and his wife, Mary Hilliard Wilson, established the
            Orme Wilson Fellowship to honor his father and grandfather, Orme
            Wilson Jr. and Orme Wilson, both of whom had long careers as U.S.
            Foreign Service officers. The fellowship supports the next
            generation of global leaders, specifically SAIS students from
            underrepresented communities.
          </p>

          <p>
            Providing affordable access to education has been the Wilson
            family’s philanthropic focus for three generations. They established
            scholarships at Harvard — all three are graduates — and at Yale,
            where Wilson’s maternal grandfather, William McKee Dunn, a U.S. Army
            officer who served in both world wars, attended. Today, Wilson’s
            work on the board of the Thomas D. Clark Foundation includes helping
            to launch the foundation’s new program to promote accurate,
            comprehensive Black history in Kentucky’s public schools.
          </p>

          <p>
            It was a convergence of events last year, including renewed focus on
            social justice at the national level and SAIS’ Propel Initiative to
            attract and support diverse candidates, that sparked the Wilson
            Fellowship, Wilson says.
          </p>

          <p>
            “My own experiences studying and working with people of all
            backgrounds, and the events that have unfolded in my home city,
            specifically the killing of Breonna Taylor, and the aftermath of
            that tragic event, inspired our gift,” says Wilson. “Addressing the
            serious inequities in our country and world is a compelling need.
            One way to help do that is by making top-quality education more
            affordable to students in underrepresented groups.”
          </p>

          <p>
            The Wilson men have a history of being in parts of the world when
            justice is needed. The senior Orme Wilson joined the U.S. Diplomatic
            Service in 1920, becoming one of the first career Foreign Service
            officers. “He served in places as the world was changing,” says
            Wilson. Among his grandfather’s posts were Berlin in 1933 to 1934
            when Hitler rose to power, and Brussels as the Germans invaded and
            occupied Belgium. “My grandfather and father were so disgusted by
            the growing Nazi party strutting around [in Berlin],” Wilson
            recalls.
          </p>

          <p>
            Wilson recalls his father’s story of attending the 1936 Berlin
            Olympics as a 16-year-old boy: “When Hitler came into the stadium,
            everyone in the crowd, including those in the diplomatic box, rose
            to their feet but my father and his best friend. My grandmother made
            them stand to avoid a diplomatic incident.”
          </p>

          <p>
            His father, Orme Wilson Jr., was a World War II aviator and later a
            pilot for Pan Am, who joined the Foreign Service in 1950. Among his
            numerous posts were post-war Germany in Frankfurt, both Belgrade and
            Zagreb in the former Yugoslavia, and the Air War College in Alabama,
            at the height of the Civil Rights Movement. “We lived in Montgomery
            during the Selma marches in 1965,” Wilson says.
          </p>

          <p>
            “As public servants and American diplomats, my grandfather and
            father understood the challenges that the world presented as it
            changed and became increasingly interconnected,” Wilson adds. And he
            says that traveling the globe as a “Foreign Service kid” made an
            impact: “The world is extraordinarily diverse, and we have to
            embrace that diversity. Everyone at SAIS benefits from different
            perspectives, which provide a richer texture and environment for
            learning.”
          </p>

          <p>
            Wilson briefly considered the family business. He even passed the
            rigorous Foreign Service exams and interned at the American Embassy
            in Kigali, Rwanda, following his graduation from Harvard. At SAIS,
            he immersed himself in African studies, American foreign policy, and
            international economics. “One particularly valuable experience was
            serving as a researcher for Professor David Calleo on his
            well-received book on U.S. foreign economic policy,{' '}
            <em>The Imperious Economy</em> (1982),” Wilson remembers.
          </p>

          <p>
            After SAIS, though, he chose another path: banking and then an
            entrepreneurial career as the owner of a Louisville commercial
            printing and graphics company. His international experience and
            education provided a competitive edge when dealing with foreign
            companies, but it was his hands-on experience as a business owner
            that reinforced his family’s legacy of creating educational
            opportunities.
          </p>

          <p>
            “This was the greatest education I ever had,” Wilson says proudly.
            “I became very aware of people’s circumstances. I had almost no
            college graduates among my employees, and yet there were many who
            were extraordinarily brilliant but just had never been able to
            afford higher education.”
          </p>
          <p>
            He and his wife are thrilled that their gift, which includes a
            multiyear pledge and a bequest, is matched by the Michael D. White
            Endowed Scholar Fund and the Flaherty Match at SAIS.
          </p>
          <p>
            “This makes what we are doing to support the Propel Initiative so
            much more robust,” he adds. “My hope is that partly through the Orme
            Wilson Fellowship, the body of SAIS graduate students will be a rich
            reflection of the composition of our country and world.”{' '}
            <em>Sarah Achenbach</em>
          </p>
        </Core.ContentSection>

        <Core.MoreResources title="Ways to Give">
          <p>
            Your annual gift makes a difference!{' '}
            <a href="https://sais.jhu.edu/giving/make-gift">
              Make your tax-deductible gift online today.
            </a>
          </p>

          <br />

          <p>
            Make a lasting impact at SAIS by establishing an endowment or legacy
            gift. Email{' '}
            <a href="mailto:sais.development@jhu.edu">
              sais.development@jhu.edu
            </a>{' '}
            for more information.
          </p>

          <br />

          <p>
            To learn more about the Christian Herter Society, visit{' '}
            <a href="https://www.sais-jhu.edu/atoz/christian-herter-society#welcome">
              sais-jhu.edu/atoz/christian-herter-society
            </a>
            .
          </p>

          <br />

          <p>
            To learn more about SAIS Europe Special Initiatives and Class
            Initiatives visit{' '}
            <a href="https://europe.jhu.edu/initiatives/">
              europe.jhu.edu/initiatives/
            </a>
            .
          </p>
        </Core.MoreResources>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    philanthropyDefinition: file(
      relativePath: { regex: $directory }
      name: { eq: "philanthropy-definition" }
    ) {
      ...ContentWidthImage
    }

    renataZilliMontero: file(
      relativePath: { regex: $directory }
      name: { eq: "renata_zilli_montero" }
    ) {
      ...MarginaliaImage
    }

    andreasBaum: file(
      relativePath: { regex: $directory }
      name: { eq: "andreas_baum" }
    ) {
      ...MarginaliaImage
    }

    isabelStuder: file(
      relativePath: { regex: $directory }
      name: { eq: "isabel_studer" }
    ) {
      ...MarginaliaImage
    }

    alethiaTilford: file(
      relativePath: { regex: $directory }
      name: { eq: "alethia_tilford" }
    ) {
      ...MarginaliaImage
    }

    ormeWilsonJrCarter: file(
      relativePath: { regex: $directory }
      name: { eq: "orme_wilson_jr_carter" }
    ) {
      ...MarginaliaImage
    }

    ormeWilsonJrTito: file(
      relativePath: { regex: $directory }
      name: { eq: "orme_wilson_jr_tito" }
    ) {
      ...MarginaliaImage
    }

    ormeWilsonSr: file(
      relativePath: { regex: $directory }
      name: { eq: "orme_wilson_sr" }
    ) {
      ...MarginaliaImage
    }
  }
`;

export default DepartmentContent;
